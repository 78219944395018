.clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.list-unstyled {
 padding: 0;
 list-style: none;
}
.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-success {
  color: var(--feedback--success);
}
.text-info {
  color: var(--feedback--info);
}
.text-warning {
  color: var(--feedback--warning);
}
.text-danger {
  color: var(--feedback--error);
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-center-15 {
  margin: auto 15px;
}
.textbox-md {
  margin: 0 auto;
}

.align-textbox {
  margin: 20px auto 20px auto;
  width: 80%;
}

.disclaimer{
  font-size: 10px;
  font-size: 1rem;
  font-weight: lighter;
  padding: 0 25px;
  text-align: center;
}

thead[data-test="table-foot"]{
  display: none;
}

table{
  background-color: white;
}

li.page-item:not(active){
  background-color: white;
}
.bg-custom-1 {
  background-color: #85144b;
}

.bg-custom-2 {
background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}

.toastify-color-error {
  background-color: #e74c3c;
}